import "@cdui/faq"
import "@cdui/hero"
import "@cdui/button"
import "@cdui/header"
import "@cdui/slider"
import "@cdui/section"
import "@cdui/cta-footer"
import "@cdui/pricing-card"
import "@cdui/product-feature"

const routes = {
    "La solution": "/",
    "L'app": b2bUrl,
    "Skapánê": "http://skapane.ai",
    "Simulateur": "/simulator.html",
    "Tarifs": "/pricing.html",
    "Contactez l'équipe": "https://skapane.ai/contact.html",
}

const header = document.createElement("cdui-header")
header.innerHTML += `<div slot="logo"></div>`
header.innerHTML += `<a slot="title" href="/" style="margin: 10px 50px;"><img style="height:50px;" src="img/clicdossier-rectangle-transparent-fond-blanc.svg"/></a>`
for (const [title, path] of Object.entries(routes)) {
    const selected = (path === location.pathname) ? "selected" : ""
    header.innerHTML += `<a href="${path}" ${selected}>${title}</a>`
}

header.innerHTML += `
<div id="header-buttons">
    <cdui-button class="blue" onclick="window.location.href='/pricing.html'">J'en profite</cdui-button>
    <cdui-button class="ghost" onclick="window.location.href='${b2bUrl}'">Me connecter</cdui-button>
</div>
`

const footer = document.createElement("div")
footer.id = "footer"
footer.innerHTML = `
    <cdui-cta-footer>
        <p slot="text-left">
            Solution d'automatisation intelligente des processus en 
            Banque, Assurance et Service Public disponible en mode SaaS.
        </p>
        <div slot="text-middle">
            <p>
                Euratechnologies<br>
                165 Avenue de Bretagne<br>
                59000 Lille
            </p>
            <p>
                Tél. : 06 37 82 81 64
            </p>
            <p>
                Email : info@skapane.ai
            </p>
        </div>

        <a slot="link" href="https://skapane.ai/nos-offres.html#ipa-by-skapane">IPA by Skapánê</a>
        <a slot="link" href="https://skapane.ai/nos-offres.html#traitement-automatise-documents">Traitement automatisé de documents</a>
        <a slot="link" href="https://skapane.ai/nos-offres.html#conformite">Conformité</a>

        <a slot="link2" href="https://skapane.ai/skapane.html">Skapánê</a>
        <a slot="link2" href="https://skapane.ai/mentions-legales.html">Mentions légales</a>
        <a slot="link2" href="https://skapane.ai/confidentialite.html">Politique de confidentialité</a>
        <a slot="link2" href="resources/ClicDossier-CGU.pdf" target="_blank">CGV</a>

        <div slot="image"><img src="img/taux-d-acceptation-maximise-clicdossier.png"/></div>
		
		<cdui-button id="cta-button" slot="button" onclick="window.location.href='https://skapane.ai/contact.html'">Contactez l'équipe</cdui-button>
    </cdui-cta-footer>
`

window.onload = () => {
    document.body.prepend(header)
    document.body.append(footer)

    if("/pricing.html" === location.pathname){
        document.getElementById("tarif-standard-cta").onclick=function() { window.location.href=b2bRegistrationUrl };
        document.getElementById("tarif-business-cta").onclick=function() { window.location.href=b2bRegistrationUrl };
        document.getElementById("tarif-premium-cta").onclick=function() { window.location.href=b2bRegistrationUrl };
        document.getElementById("tarif-entreprise-cta").onclick=function() { window.location.href="https://skapane.ai/contact.html"};
    }
}
